import {
    IconButton,
    Link,
    Stack,
    Text,
} from '@fluentui/react';
import {
    CalendarAdd20Filled,
    CalendarAdd20Regular,
    MoreCircle20Filled,
    MoreCircle20Regular,
    ShoppingBag20Filled,
    ShoppingBag20Regular
} from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import './SessionAnswer.css';
import Markdown, { Components } from "react-markdown";
import rehypeRaw from "rehype-raw";
import { MetadataItem } from '../../api';
interface Props {
    metadata: MetadataItem[];
    attendeeType: string;
    intent: string;
    messageIndex: number;
    statusBackpack: boolean[][];
    statusSession: boolean[][];
}

/**
 * The `SessionAnswer` component displays a session card metadata items. 
 * It allows users to interact with session items by adding them to their schedule or backpack. 
 * The component also provides options to expand/collapse additional details for each session.
 *
 * @param metadata - An array of MetadataItem objects representing session data.
 * @param attendeeType - The type of attendee, used to determine UI options.
 * @param intent - The user's intent, affecting the component's behavior.
 * @returns A React functional component that renders session cards with interactive options.
 */
export const SessionAnswer: React.FC<Props> = ({ metadata, attendeeType, intent, messageIndex, statusBackpack, statusSession }) => {
    const [expandedSessions, setExpandedSessions] = useState<string[]>([]);
    const [disabledBackpackButtons, setDisabledBackpackButtons] = useState<Record<string, boolean>>({});
    const [disabledScheduleButtons, setDisabledScheduleButtons] = useState<Record<string, boolean>>({});
    const [sessionList, setSessionList] = useState<any[]>([]);
    const [sendPostMessage, setSendPostMessage] = useState(false);
    const [addedToBackpackButtons, setaddedToBackpackButtons] = useState<Record<string, boolean>>({});
    const [addedToScheduleButtons, setaddedToScheduleButtons] = useState<Record<string, boolean>>({});
    const [showMore, setShowMore] = useState(false);
    const handleToggleSession = (sessionID: string) => {
        if (expandedSessions.includes(sessionID)) {
            setExpandedSessions(expandedSessions.filter((id) => id !== sessionID));
        } else {
            setExpandedSessions([...expandedSessions, sessionID]);
        }
    };
    interface SessionData {
        objectId: string;
        objectType: string;
        action: string;
    }
    const sectionStyles = {
        border: "1px solid #e5e5e5",
        padding: "5px 10px",
        borderRadius: "4px",
        backgroundColor: "#f5f5f5"
    };
    useEffect(() => {
        if (sendPostMessage && sessionList.length > 0) {
            const message = {
                type: 'command',
                data: sessionList,
            };
            window.parent.postMessage(message, '*');
            setSessionList([]);
            setSendPostMessage(false);
        }

    }, [sendPostMessage, sessionList]);

    useEffect(() => {
        /**
         * Handles the response received from a post message event.
         * @param event - The event object containing the response data.
         */
        function handlePostMessageResponse(event: { data: any; }) {
            const response = event.data;

            if (response.type === "info") {
                // Handle based on the message code
                switch (response.data.message) {
                    case 200:
                    case 204:
                        response.data.data.map((sessionData: SessionData) => {
                            if (metadata.some(session => session.objectId === sessionData.objectId)) {
                                if (sessionData.action === "addBackpack") {
                                    setaddedToBackpackButtons((prevCheckbackpack) => ({
                                        ...prevCheckbackpack,
                                        [sessionData.objectId]: true,
                                    }));
                                } else if (sessionData.action === "addSchedule") {
                                    setaddedToScheduleButtons((prevCheckschedule) => ({
                                        ...prevCheckschedule,
                                        [sessionData.objectId]: true,
                                    }));
                                }
                                return null; // map function requires a return
                            }
                        });
                        break;
                    default:
                        response.data.data.map((sessionData: SessionData) => {
                            const sessionToRemove = metadata.find(session => session.objectId === sessionData.objectId);
                            if (sessionToRemove) {
                                if (sessionData.action === "addBackpack") {
                                    setDisabledBackpackButtons((prevDisabledBackpackButtons) => {
                                        const { [sessionToRemove.id]: _, ...updatedBackpackButtons } = prevDisabledBackpackButtons;
                                        return updatedBackpackButtons;
                                    });
                                } else if (sessionData.action === "addSchedule") {
                                    setDisabledScheduleButtons((prevDisabledScheduleButtons) => {
                                        const { [sessionToRemove.id]: _, ...updatedScheduleButtons } = prevDisabledScheduleButtons;
                                        return updatedScheduleButtons;
                                    });
                                }
                                return null; // map function requires a return
                            }
                        });
                }
            }
        }
        // Add the event listener when the component mounts
        window.addEventListener('message', handlePostMessageResponse);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('message', handlePostMessageResponse);
        };
    }, []);
    useEffect(() => {
        if (intent && intent.toLowerCase().includes("action")) {
            addActionDataToSessionList(metadata);
        }
    }, [intent, metadata]);

    const renderers: Partial<Components> = {
        a: ({ href, children }) => (
            <Link
                href={href as string}
                target="_blank"
                title={`${href as string}\nClick to follow link in a new tab`}
                inline
            >
                {children}
            </Link>
        ),
    };

    /**
     * Adds action data to the session list and updates the state to the existing sessionList state to be passed in post message.
     * @param sessions - The list of sessions to loop through and prepare data.
     */
    const addActionDataToSessionList = (sessions: MetadataItem[]) => {
        // Loop through the sessions and prepare data
        const actionSessionList: any[] = [];

        sessions.forEach(session => {
            if (session.addToBackpack === "1") {
                const backpackData = {
                    objectId: session.objectId,
                    action: 'addBackpack',
                    object: 'session',
                };
                actionSessionList.push(backpackData);
            }

            if (session.addToSchedule === "1") {
                const scheduleData = {
                    objectId: session.objectId,
                    action: 'addSchedule',
                    object: 'session',
                };
                actionSessionList.push(scheduleData);
            }
        });

        // Add the data to the existing sessionList state
        setSessionList(prevData => [...prevData, ...actionSessionList]);

        // Call setSendPostMessage(true) once data is added
        setSendPostMessage(true);

    };

    /**
     * Adds session data to the session list to be passed for postmessage and updates the disabled state of the button.
     * @param session - The session metadata item to add to the session list.
     * @param clickaction - The action to perform on the session (addBackpack or addSchedule).
     */
    const addDataToSessionList = (session: MetadataItem, clickaction: string, index: number) => {
        if (clickaction === "addBackpack") {
            setDisabledBackpackButtons((prevState) => ({
                ...prevState,
                [session.id]: true,
            }));
            statusBackpack[messageIndex][index] = true;
            localStorage.setItem('setBack', JSON.stringify(statusBackpack));
        }
        else if (clickaction === "addSchedule") {
            setDisabledScheduleButtons((prevState) => ({
                ...prevState,
                [session.id]: true,
            }));
            statusSession[messageIndex][index] = true;
            localStorage.setItem('setSession', JSON.stringify(statusSession));
        }
        // Update the disabled state of the button
        const data = {
            objectId: session.objectId,
            action: clickaction,
            object: 'session',
        };

        setSessionList((prevData) => [...prevData, data]);
        if (!sendPostMessage) {
            // Set a timer to send data to the parent after 2 seconds
            setTimeout(() => {
                setSendPostMessage(true);
            }, 2000);
        }
    };

    return (
        <div>
            <Stack>
                    {intent.trim().toLowerCase() === "buildschedule" ?
                        (<Stack tokens={{ childrenGap: 10 }} styles={{ root: { maxWidth: '600px', borderRadius: '25px' } }}>
                            {metadata.slice(0, 5).map((session, index) => (
                                <div key={session.id} style={{ ...sectionStyles }}>
                                    <div className="session-block">
                                        <Stack tokens={{ childrenGap: 2 }}>
                                            <Stack.Item>
                                                <Stack
                                                    horizontal
                                                    horizontalAlign="space-between"
                                                    verticalAlign="start"
                                                    tokens={{ childrenGap: 10 }}>
                                                    <Stack.Item className="ellipsesText">
                                                        {session.title && < Text variant="medium">{session.title}</Text>}
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        {session.sessionURL === null ? (
                                                            <Text variant="small">{session.id}</Text>
                                                        ) : (
                                                            <Link data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_sessionlink" })} href={session.sessionURL} target="_blank">
                                                                {session.id && <Text variant="small" style={{ color: '#3E45C9' }}>{session.id}</Text>}
                                                            </Link>
                                                        )}
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            <Stack.Item>
                                                {session.primarySpeaker && < Text variant="small">{session.primarySpeaker}</Text>}
                                            </Stack.Item>

                                            <Stack.Item>
                                                <Stack horizontal horizontalAlign="space-between">
                                                    {session.eventTime && session.room ? (
                                                        <Text variant="small" className="ellipsesText">{session.eventTime}, <Markdown
                                                            rehypePlugins={[rehypeRaw]}
                                                            components={renderers}>
                                                            {session.room}
                                                        </Markdown>
                                                        </Text>
                                                    ) : session.eventTime ? (
                                                        <Text variant="small" className="ellipsesText">{session.eventTime}</Text>
                                                    ) : session.room ? (
                                                                <Text variant="small" className="ellipsesText"><Markdown
                                                                    rehypePlugins={[rehypeRaw]}
                                                                    components={renderers}
                                                                >
                                                                    {session.room}
                                                                </Markdown></Text>
                                                    ) : <></>}

                                                    <Stack>
                                                        {attendeeType === null || attendeeType.trim().toLowerCase().includes("anonymous") ?
                                                            (<Stack.Item>
                                                                {!expandedSessions.includes(session.id) ? (
                                                                    <IconButton
                                                                        className="icon_button"
                                                                        title="Show more details"
                                                                        onClick={() => handleToggleSession(session.id)}>
                                                                        <MoreCircle20Regular className="icon" />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton
                                                                        className="icon_button"
                                                                        title="Show less details"
                                                                        onClick={() => handleToggleSession(session.id)}>
                                                                        <MoreCircle20Filled className="icon" />
                                                                    </IconButton>

                                                                )}
                                                            </Stack.Item>)
                                                            :
                                                            (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>
                                                                <span className={`visually_hidden`} aria-live="polite" aria-atomic="true">
                                                                    {session.objectId && addedToScheduleButtons[session.objectId] ? 'Added to Schedule' : 'Add to Schedule'}
                                                                </span>
                                                                {session.objectId && addedToScheduleButtons[session.objectId] ? (
                                                                    <IconButton
                                                                        title='Added to Schedule'
                                                                        className={`icon-wrapper disabled-button icon-button`}
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_addtoschedule" })}
                                                                    >
                                                                        <CalendarAdd20Filled className={"icon"} />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton
                                                                        title='Add to Schedule'
                                                                        onClick={() => addDataToSessionList(session, "addSchedule", index)}
                                                                        className={`icon-wrapper ${(disabledScheduleButtons[session.id] || session.isSchedule === "0" || statusSession[messageIndex][index]) ? 'disabled-button icon-button' : 'icon-button'}`}
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_addtoschedule" })}
                                                                    >
                                                                        <CalendarAdd20Regular className="icon default-icon" />
                                                                    </IconButton>
                                                                )}
                                                                <span className={`visually_hidden`} aria-live="polite" aria-atomic="true">
                                                                    {session.objectId && addedToBackpackButtons[session.objectId] ? 'Added to Backpack' : 'Add to Backpack'}
                                                                </span>
                                                                {session.objectId && addedToBackpackButtons[session.objectId] ? (
                                                                    <IconButton
                                                                        title='Added to Backpack'
                                                                        className={`icon-wrapper disabled-button icon-button`}
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_addtobackpack" })}
                                                                    >
                                                                        <ShoppingBag20Filled className={"icon"} />
                                                                    </IconButton>

                                                                ) : (
                                                                    <IconButton
                                                                        title="Add to Backpack"
                                                                        onClick={() => addDataToSessionList(session, "addBackpack", index)}
                                                                        className={`icon-wrapper ${(disabledBackpackButtons[session.id] || session.isBackpack === "0" || statusBackpack[messageIndex][index]) ? 'disabled-button icon-button' : 'icon-button'}`}
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_addtobackpack" })}
                                                                    >
                                                                        <ShoppingBag20Regular className="icon default-icon" />
                                                                    </IconButton>
                                                                )}
                                                                {!expandedSessions.includes(session.id) ? (
                                                                    <IconButton
                                                                        title="Show more details"
                                                                        onClick={() => handleToggleSession(session.id)}
                                                                        className="icon-button"
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_showmoredetails" })}
                                                                    >
                                                                        <MoreCircle20Regular className="icon" />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton
                                                                        title="Show less details"
                                                                        onClick={() => handleToggleSession(session.id)}
                                                                        className="icon-button"
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_showlessdetails" })}
                                                                    >
                                                                        <MoreCircle20Filled className="icon" />
                                                                    </IconButton>
                                                                )}
                                                            </Stack>
                                                            )
                                                        }
                                                    </Stack>
                                                </Stack>
                                            </Stack.Item>
                                        </Stack>
                                        {expandedSessions.includes(session.id) && (
                                            <Stack tokens={{ childrenGap: 2 }} className="session-details">
                                                <div className="horizontalLine" />
                                                {session.additionalSpeakers && (
                                                    <Text variant="small" >
                                                        Additional Speakers: {session.additionalSpeakers}
                                                    </Text>
                                                )}
                                                {session.description && (
                                                    <Text variant="small"> {session.description}</Text>
                                                )}
                                            </Stack>
                                        )}
                                    </div>
                                </div>
                            ))}
                            {showMore && metadata.slice(5).map((session, index) => (
                                <div key={session.id} style={{ ...sectionStyles }}>
                                    <div className="session-block">
                                        <Stack tokens={{ childrenGap: 2 }}>
                                            <Stack.Item>
                                                <Stack
                                                    horizontal
                                                    horizontalAlign="space-between"
                                                    verticalAlign="start"
                                                    tokens={{ childrenGap: 10 }}>
                                                    <Stack.Item className="ellipsesText">
                                                        {session.title && < Text variant="medium">{session.title}</Text>}
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        {session.sessionURL === null ? (
                                                            <Text variant="small">{session.id}</Text>
                                                        ) : (
                                                            <Link data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_sessionlink" })} href={session.sessionURL} target="_blank">
                                                                {session.id && <Text variant="small" style={{ color: '#3E45C9' }}>{session.id}</Text>}
                                                            </Link>
                                                        )}
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            <Stack.Item>
                                                {session.primarySpeaker && < Text variant="small">{session.primarySpeaker}</Text>}
                                            </Stack.Item>

                                            <Stack.Item>
                                                <Stack horizontal horizontalAlign="space-between">
                                                    {session.eventTime && session.room ? (
                                                       
                                                        <Text variant="small" className="ellipsesText">{session.eventTime}, <Markdown
                                                            rehypePlugins={[rehypeRaw]}
                                                            components={renderers}>
                                                            {session.room}
                                                        </Markdown>
                                                        </Text>
                                                    ) : session.eventTime ? (
                                                        <Text variant="small" className="ellipsesText">{session.eventTime}</Text>
                                                    ) : session.room ? (
                                                        <Text variant="small" className="ellipsesText">{session.room}</Text>
                                                    ) : <></>}

                                                    <Stack>
                                                        {attendeeType === null || attendeeType.trim().toLowerCase().includes("anonymous") ?
                                                            (<Stack.Item>
                                                                {!expandedSessions.includes(session.id) ? (
                                                                    <IconButton
                                                                        className="icon_button"
                                                                        title="Show more details"
                                                                        onClick={() => handleToggleSession(session.id)}>
                                                                        <MoreCircle20Regular className="icon" />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton
                                                                        className="icon_button"
                                                                        title="Show less details"
                                                                        onClick={() => handleToggleSession(session.id)}>
                                                                        <MoreCircle20Filled className="icon" />
                                                                    </IconButton>

                                                                )}
                                                            </Stack.Item>)
                                                            :
                                                            (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>
                                                                <span className={`visually_hidden`} aria-live="polite" aria-atomic="true">
                                                                    {session.objectId && addedToScheduleButtons[session.objectId] ? 'Added to Schedule' : 'Add to Schedule'}
                                                                </span>
                                                                {session.objectId && addedToScheduleButtons[session.objectId] ? (
                                                                    <IconButton
                                                                        title='Added to Schedule'
                                                                        className={`icon-wrapper disabled-button icon-button`}
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_addtoschedule" })}
                                                                    >
                                                                        <CalendarAdd20Filled className={"icon"} />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton
                                                                        title='Add to Schedule'
                                                                        onClick={() => addDataToSessionList(session, "addSchedule", index)}
                                                                        className={`icon-wrapper ${(disabledScheduleButtons[session.id] || session.isSchedule === "0" || statusSession[messageIndex][index]) ? 'disabled-button icon-button' : 'icon-button'}`}
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_addtoschedule" })}
                                                                    >
                                                                        <CalendarAdd20Regular className="icon default-icon" />
                                                                    </IconButton>
                                                                )}
                                                                <span className={`visually_hidden`} aria-live="polite" aria-atomic="true">
                                                                    {session.objectId && addedToBackpackButtons[session.objectId] ? 'Added to Backpack' : 'Add to Backpack'}
                                                                </span>
                                                                {session.objectId && addedToBackpackButtons[session.objectId] ? (
                                                                    <IconButton
                                                                        title='Added to Backpack'
                                                                        className={`icon-wrapper disabled-button icon-button`}
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_addtobackpack" })}
                                                                    >
                                                                        <ShoppingBag20Filled className={"icon"} />
                                                                    </IconButton>

                                                                ) : (
                                                                    <IconButton
                                                                        title="Add to Backpack"
                                                                        onClick={() => addDataToSessionList(session, "addBackpack", index)}
                                                                        className={`icon-wrapper ${(disabledBackpackButtons[session.id] || session.isBackpack === "0" || statusBackpack[messageIndex][index]) ? 'disabled-button icon-button' : 'icon-button'}`}
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_addtobackpack" })}
                                                                    >
                                                                        <ShoppingBag20Regular className="icon default-icon" />
                                                                    </IconButton>
                                                                )}
                                                                {!expandedSessions.includes(session.id) ? (
                                                                    <IconButton
                                                                        title="Show more details"
                                                                        onClick={() => handleToggleSession(session.id)}
                                                                        className="icon-button"
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_showmoredetails" })}
                                                                    >
                                                                        <MoreCircle20Regular className="icon" />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton
                                                                        title="Show less details"
                                                                        onClick={() => handleToggleSession(session.id)}
                                                                        className="icon-button"
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_showlessdetails" })}
                                                                    >
                                                                        <MoreCircle20Filled className="icon" />
                                                                    </IconButton>
                                                                )}
                                                            </Stack>
                                                            )
                                                        }
                                                    </Stack>
                                                </Stack>
                                            </Stack.Item>
                                        </Stack>
                                        {expandedSessions.includes(session.id) && (
                                            <Stack tokens={{ childrenGap: 2 }} className="session-details">
                                                <div className="horizontalLine" />
                                                {session.additionalSpeakers && (
                                                    <Text variant="small" >
                                                        Additional Speakers: {session.additionalSpeakers}
                                                    </Text>
                                                )}
                                                {session.description && (
                                                    <Text variant="small"> {session.description}</Text>
                                                )}
                                            </Stack>
                                        )}
                                    </div>
                                </div>
                            ))}
                            {metadata.length > 5 && (
                                <button onClick={() => setShowMore(!showMore)} className="show-more-button">
                                    {showMore ? "Show Less" : "Show More"}
                                </button>
                            )}
                        </Stack>)
                        :
                        (<Stack tokens={{ childrenGap: 10 }} styles={{ root: { maxWidth: '600px', borderRadius: '25px' } }}>
                            {metadata.map((session, index) => (
                                <div key={session.id} style={{ ...sectionStyles }}>
                                    <div className="session-block">
                                        <Stack tokens={{ childrenGap: 2 }}>
                                            <Stack.Item>
                                                <Stack
                                                    horizontal
                                                    horizontalAlign="space-between"
                                                    verticalAlign="start"
                                                    tokens={{ childrenGap: 10 }}>
                                                    <Stack.Item className="ellipsesText">
                                                        {session.title && < Text variant="medium">{session.title}</Text>}
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        {session.sessionURL === null ? (
                                                            <Text variant="small">{session.id}</Text>
                                                        ) : (
                                                            <Link data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_sessionlink" })} href={session.sessionURL} target="_blank">
                                                                {session.id && <Text variant="small" style={{ color: '#3E45C9' }}>{session.id}</Text>}
                                                            </Link>
                                                        )}
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            <Stack.Item>
                                                {session.primarySpeaker && < Text variant="small">{session.primarySpeaker}</Text>}
                                            </Stack.Item>

                                            <Stack.Item>
                                                <Stack horizontal horizontalAlign="space-between">
                                                    {session.eventTime && session.room ? (
                                                        <Text variant="small" className="ellipsesText">{session.eventTime}, <Markdown
                                                                rehypePlugins={[rehypeRaw]}
                                                                components={renderers}>
                                                                {session.room}
                                                        </Markdown>
                                                        </Text>
                                                    ) : session.eventTime ? (
                                                        <Text variant="small" className="ellipsesText">{session.eventTime}</Text>
                                                    ) : session.room ? (
                                                        <Text variant="small" className="ellipsesText">{session.room}</Text>
                                                    ) : <></>}

                                                    <Stack>
                                                        {attendeeType === null || attendeeType.trim().toLowerCase().includes("anonymous") ?
                                                            (<Stack.Item>
                                                                {!expandedSessions.includes(session.id) ? (
                                                                    <IconButton
                                                                        className="icon_button"
                                                                        title="Show more details"
                                                                        onClick={() => handleToggleSession(session.id)}>
                                                                        <MoreCircle20Regular className="icon" />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton
                                                                        className="icon_button"
                                                                        title="Show less details"
                                                                        onClick={() => handleToggleSession(session.id)}>
                                                                        <MoreCircle20Filled className="icon" />
                                                                    </IconButton>

                                                                )}
                                                            </Stack.Item>)
                                                            :
                                                            (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>
                                                                <span className={`visually_hidden`} aria-live="polite" aria-atomic="true">
                                                                    {session.objectId && addedToScheduleButtons[session.objectId] ? 'Added to Schedule' : 'Add to Schedule'}
                                                                </span>
                                                                {session.objectId && addedToScheduleButtons[session.objectId] ? (
                                                                    <IconButton
                                                                        title='Added to Schedule'
                                                                        className={`icon-wrapper disabled-button icon-button`}
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_addtoschedule" })}
                                                                    >
                                                                        <CalendarAdd20Filled className={"icon"} />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton
                                                                        title='Add to Schedule'
                                                                        onClick={() => addDataToSessionList(session, "addSchedule", index)}
                                                                        className={`icon-wrapper ${(disabledScheduleButtons[session.id] || session.isSchedule === "0" || statusSession[messageIndex][index]) ? 'disabled-button icon-button' : 'icon-button'}`}
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_addtoschedule" })}
                                                                    >
                                                                        <CalendarAdd20Regular className="icon default-icon" />
                                                                    </IconButton>
                                                                )}
                                                                <span className={`visually_hidden`} aria-live="polite" aria-atomic="true">
                                                                    {session.objectId && addedToBackpackButtons[session.objectId] ? 'Added to Backpack' : 'Add to Backpack'}
                                                                </span>
                                                                {session.objectId && addedToBackpackButtons[session.objectId] ? (
                                                                    <IconButton
                                                                        title='Added to Backpack'
                                                                        className={`icon-wrapper disabled-button icon-button`}
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_addtobackpack" })}
                                                                    >
                                                                        <ShoppingBag20Filled className={"icon"} />
                                                                    </IconButton>

                                                                ) : (
                                                                    <IconButton
                                                                        title="Add to Backpack"
                                                                        onClick={() => addDataToSessionList(session, "addBackpack", index)}
                                                                        className={`icon-wrapper ${(disabledBackpackButtons[session.id] || session.isBackpack === "0" || statusBackpack[messageIndex][index]) ? 'disabled-button icon-button' : 'icon-button'}`}
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_addtobackpack" })}
                                                                    >
                                                                        <ShoppingBag20Regular className="icon default-icon" />
                                                                    </IconButton>
                                                                )}
                                                                {!expandedSessions.includes(session.id) ? (
                                                                    <IconButton
                                                                        title="Show more details"
                                                                        onClick={() => handleToggleSession(session.id)}
                                                                        className="icon-button"
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_showmoredetails" })}
                                                                    >
                                                                        <MoreCircle20Regular className="icon" />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton
                                                                        title="Show less details"
                                                                        onClick={() => handleToggleSession(session.id)}
                                                                        className="icon-button"
                                                                        data-m={JSON.stringify({ "aN": "responsecard", "cN": session.id + "_showlessdetails" })}
                                                                    >
                                                                        <MoreCircle20Filled className="icon" />
                                                                    </IconButton>
                                                                )}
                                                            </Stack>
                                                            )
                                                        }
                                                    </Stack>
                                                </Stack>
                                            </Stack.Item>
                                        </Stack>
                                        {expandedSessions.includes(session.id) && (
                                            <Stack tokens={{ childrenGap: 2 }} className="session-details">
                                                <div className="horizontalLine" />
                                                {session.additionalSpeakers && (
                                                    <Text variant="small" >
                                                        Additional Speakers: {session.additionalSpeakers}
                                                    </Text>
                                                )}
                                                {session.description && (
                                                    <Text variant="small"> {session.description}</Text>
                                                )}
                                            </Stack>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </Stack>)
                    }
                </Stack>
        </div>
    );
};