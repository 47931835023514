import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";
import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";

/**
 * Props for AnswerLoading component
 * @interface
 */
interface Props {
    lastQuestionRef?: any;
}

/**
 * Renders a loading animation while waiting for an answer to be generated.
 * @param lastQuestionRef - A reference to the last question asked by the user.
 * @returns A React component that displays a loading animation and the last question asked by the user.
 */

export const AnswerLoading = ({ lastQuestionRef }:Props) => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={styles.answerContainer} verticalAlign="space-between">
                <Stack.Item grow >
                <AnswerIcon />
                    <p className={styles.answerText} >
                        Searching for: <span style={{ fontWeight: '500' }}>{lastQuestionRef}</span>
                    </p>
                </Stack.Item>
                <Stack.Item grow>
                    <p className={styles.answerText} >
                        Generating answer for you
                        <span className={styles.loadingdots} />
                    </p>
                </Stack.Item>
            </Stack>
        </animated.div>
    );
};

export default AnswerLoading;