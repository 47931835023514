import React, { useState, useEffect } from "react";
import {
    Popover,
    PopoverSurface,
    PopoverTrigger,
} from "@fluentui/react-components";
import type { PopoverProps } from "@fluentui/react-components";
import { Book20Regular, Book20Filled } from "@fluentui/react-icons";
import "./PromptBook.module.css";
import styles from "./PromptBook.module.css";

/**
 * Props for the HoverableClickableItem component.
 * @typedef HoverableClickableItemProps
 * @type {object}
 * @property {string} text - The text to display in the item.
 * @property {function} makeApiRequest - A function to make an API request when the item is clicked.
 * @property {any} open - A boolean indicating whether the item is open.
 * @property {any} onOpenChange - A function to handle changes to the open state of the item.
 * @property {any} lastQuestionRef - A ref to the last question asked.
 * @property {boolean} isLoading - A boolean indicating whether the item is currently loading.
 */
interface HoverableClickableItemProps {
    text: string;
    makeApiRequest: (question: string, followUpFlag: string) => void;
    open: any;
    onOpenChange: any;
    lastQuestionRef?: any;
    isLoading?: boolean;
}

/**
 * A component that renders a hoverable and clickable item with text and a button.
 * @param {HoverableClickableItemProps} props - The props for the HoverableClickableItem component.
 * @returns {JSX.Element} - The HoverableClickableItem component.
 */
const HoverableClickableItem: React.FC<HoverableClickableItemProps> = ({ text, makeApiRequest, open, onOpenChange, lastQuestionRef, isLoading }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleHover = () => {
        setIsHovered(true);
    };

    const handleUnhover = () => {
        setIsHovered(false);
    };

    const handleClick = () => {
        makeApiRequest(text, "0");
        onOpenChange(null, { open: false });
    };

    const sectionStyles = {
        padding: "5px 5px",
        borderRadius: "6px",
        backgroundColor: isHovered ? "#f5f5f5" : "initial",
        cursor: "pointer",
    };

    return (
        <div
            className={styles.contentItem}
            style={{ ...sectionStyles }}
            onMouseEnter={handleHover}
            onMouseLeave={handleUnhover}
        >
            <button title={text} data-m={JSON.stringify({"aN": "promtguide", "cN": {text} })} className={styles.buttonPromptData} disabled={isLoading} onClick={handleClick}>
                
                {text}
            </button>
        </div>
    );
};


/**
 * Props for the ExampleContent component.
 * @typedef ExampleContentProps
 * @type {object}
 * @property {function} makeApiRequest - A function to make an API request when an item is clicked.
 * @property {any} open - A boolean indicating whether the item is open.
 * @property {any} onOpenChange - A function to handle changes to the open state of the item.
 * @property {Array} intentQuestions - An array of objects containing intent, question and orderBy properties.
 * @property {any} lastQuestionRef - A ref to the last question asked.
 * @property {boolean} isLoading - A boolean indicating whether the item is currently loading.
 */
interface ExampleContentProps {
    makeApiRequest: (question: string, followUpFlag: string) => void;
    open: any;
    onOpenChange: any;
    intentQuestions: Array<{ intent: string; question: string; orderBy: string }>;
    lastQuestionRef?: any;
    isLoading?: boolean;
}

/**
 * A component that renders a prompt guide with hoverable and clickable items.
 * @param {ExampleContentProps} props - The props for the ExampleContent component.
 * @returns {JSX.Element} - The ExampleContent component.
 */
const ExampleContent: React.FC<ExampleContentProps> = ({ makeApiRequest, open, onOpenChange, intentQuestions, lastQuestionRef, isLoading }) => {
    return (
        <div className={styles.contentHeader} style={{ padding: '0px 4px', gap: '2px', color: "#242424" }}>
            <p className={styles.sectionStyles} style={{ padding: "8px 4px", marginLeft: "0px", fontFamily: "Segoe UI", color: "#616161" }}>Prompt Guide</p>
            <div className={styles.hoverableClickableItemDiv}>
                {intentQuestions && intentQuestions.map((item, index) => (
                    <HoverableClickableItem
                        key={index}
                        text={item.question}
                        makeApiRequest={makeApiRequest}
                        open={open}
                        onOpenChange={onOpenChange}
                        lastQuestionRef={lastQuestionRef}
                        isLoading={isLoading}
                    />
                ))}
            </div>
        </div>
    );
};

/**
 * Props for the PromptBook component.
 * @typedef Props
 * @type {object}
 * @property {function} makeApiRequest - A function to make an API request when an item is clicked.
 * @property {Array} intentQuestions - An array of objects containing intent, question and orderBy properties.
 * @property {string} intent - A string representing the intent.
 * @property {any} lastQuestionRef - A ref to the last question asked.
 * @property {boolean} isLoading - A boolean indicating whether the item is currently loading.
 */
interface Props {
    makeApiRequest: (question: string, followUpFlag: string) => void;
    intentQuestions: Array<{ intent: string; question: string; orderBy: string }>;
    intent: string;
    lastQuestionRef?: any;
    isLoading?: boolean;
}

/**
 * The PromptBook component is a prompt guide with hoverable and clickable items.
 * @param {Props} props - The props for the PromptBook component.
 * @returns {JSX.Element} - The PromptBook component.
 */
export const PromptBook = ({ makeApiRequest, intentQuestions, intent, lastQuestionRef, isLoading }: Props) => {
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [sortIntentQuestions, setSortIntentQuestions] = useState<{ intent: string; question: string; orderBy: string; }[]>([]);
    const trimmedString = intent.trim();
    const custom = trimmedString.split(" ");

    const handleButtonClick = () => {
        setIsButtonClicked(!isButtonClicked);
    };

    const [open, setOpen] = useState(false);
    const handleOpenChange: PopoverProps["onOpenChange"] = (e, data) =>
        setOpen(data.open || false);

    useEffect(() => {
        if (intentQuestions && intentQuestions.length >= 2) {
            const sortedData = intentQuestions.sort((a, b) => {
                const intentA = a.intent;
                const intentB = b.intent;
                const orderA = parseInt(a.orderBy);
                const orderB = parseInt(b.orderBy);

                const indexA = custom.indexOf(intentA);
                const indexB = custom.indexOf(intentB);

                if (indexA === -1 && indexB === -1) {
                    // If both intents are not in the custom array, sort by orderBy
                    if (orderA === orderB) {
                        return 0;
                    }
                    return orderA - orderB;
                } else if (indexA === -1) {
                    // Sort intents not in custom array after those in the custom array
                    return 1;
                } else if (indexB === -1) {
                    // Sort intents not in custom array after those in the custom array
                    return -1;
                } else {
                    // Sort by custom order first, and then by orderBy
                    if (intentA === intentB) {
                        return orderA - orderB;
                    }
                    return indexA - indexB;
                }
            });
            setSortIntentQuestions(sortedData);
        }
    }, [intent, intentQuestions]);

    useEffect(() => {
        if (open === true) {
            setIsButtonClicked(true);
        } else {
            setIsButtonClicked(false);
        }
    }, [open]);

    return (
        <div className={styles.root} style={{ rowGap: "tokens.spacingVerticalMNudge" }}>
            <Popover open={open} onOpenChange={handleOpenChange}>
                <PopoverTrigger disableButtonEnhancement>
                    <button data-m='{"aN": "chattextbox", "cN": "Prompt Guide" }' style={{ background: "#FFFFFF", border: "none", padding: "0px", borderRadius: "4px", cursor: "pointer" }}
                        onClick={handleButtonClick}
                        title="Prompt guide"
                    >
                        {
                            isButtonClicked ? (
                                <Book20Filled
                                    style={{
                                        color: "#0F548C",
                                        padding: "1px",
                                    }}
                                />
                            ) : (
                                <Book20Regular
                                    style={{
                                        padding: "1px",
                                    }}
                                />
                            )}
                    </button>
                </PopoverTrigger>
                <PopoverSurface className={styles.container} style={{ borderRadius: "8px", padding: "4px 0px", border: "none", boxShadow: "0px 0px 6px 0px #0000001F", overflow: "hidden", backgroundColor: "#FFFFFF" }}>
                    <ExampleContent
                        makeApiRequest={makeApiRequest}
                        open={open}
                        onOpenChange={handleOpenChange}
                        intentQuestions={sortIntentQuestions}
                        lastQuestionRef={lastQuestionRef}
                        isLoading={isLoading}
                    />
                </PopoverSurface>
            </Popover>
        </div>
    );
};