import { useState } from "react";
import { Stack, TextField } from "@fluentui/react";
import { Send20Regular } from "@fluentui/react-icons";
import styles from "./QuestionInput.module.css";
import { ClearChatButton } from '../ClearChatButton/ClearChatButton';
import { PromptBook } from '../Prompt/PromptBook';
interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    inputDisables?: boolean;
    className?: string;
    ref?: React.Ref<HTMLInputElement>;
    lastQuestionRef?: any;
    isLoading?: boolean;
    clearChat: () => void;
    makeApiRequest: (question: string, followUpFlag: string) => void;
    intentQuestions: Array<{ intent: string; question: string; orderBy: string }>;
    intent: string;
    scroller: any;
    chatMessageStreamEnd: any;
}

/**
 * A component that renders a text input field and a send button for submitting questions.
 * @param onSend - A function that is called when the user submits a question.
 * @param disabled - A boolean that determines whether the input field and send button are disabled.
 * @param placeholder - An optional string that specifies the placeholder text for the input field.
 * @param clearOnSend - An optional boolean that determines whether the input field should be cleared after the user submits a question.
 * @param inputDisables - An optional boolean that determines whether the input field should be disabled.
 * @param className - An optional string that specifies the CSS class name for the component.
 * @param ref - An optional React ref object for the input field.
 * @param lastQuestionRef - An optional React ref object for the last question.
 * @param isLoading - An optional boolean that determines whether the component is in a loading state.
 * @param clearChat - A function that is called when the user clicks the clear chat button.
 * @param makeApiRequest - A function that is called when the user submits a question to make an API request.
 * @param intentQuestions - An array of objects that contain intent, question, and orderBy properties.
 * @param intent - A string that specifies the intent of the question.
 */

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, inputDisables, className, lastQuestionRef, isLoading, clearChat, makeApiRequest, intentQuestions, intent, scroller, chatMessageStreamEnd }: Props) => {
    const [question, setQuestion] = useState<string>("");

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }
        
        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
        
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <Stack className={className} >
            <Stack.Item align="start" grow style={{ width: "100%" }}>
                <TextField
                    className={styles.questionInputTextArea}
                    placeholder={placeholder}
                    resizable={false}
                    borderless
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                    disabled={inputDisables}
                    autoFocus={true}
                />
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <Stack horizontal horizontalAlign="space-between">
                        <div style={{ width: "36px" }}>
                            <ClearChatButton
                                className={styles.commandButton}
                                disabled={!lastQuestionRef.current || isLoading}
                                onClick={clearChat}
                            />
                        </div>
                        <div>
                            <PromptBook
                                makeApiRequest={makeApiRequest}
                                intentQuestions={intentQuestions}
                                intent={intent}
                                lastQuestionRef={lastQuestionRef}
                                isLoading={isLoading}
                            />
                        </div>
                    </Stack>
                    <div className={styles.questionInputButtonsContainer}>
                        <button
                            data-m='{"aN": "chattextbox", "cN": "Send" }'
                            className={`${styles.questionInputSendButton} ${sendQuestionDisabled ? styles.questionInputSendButtonDisabled : ""}`}
                            onClick={sendQuestion}
                            title="Send"
                        >
                            <Send20Regular />
                        </button>
                    </div>
                </Stack>
            </Stack.Item>
        </Stack>
    );
};
