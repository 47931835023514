import * as marked from 'marked';

/**
 * Configures the markdown renderer by overriding the link renderer to add target="_blank" and rel="noopener noreferrer" attributes to all links.
 */
export function configureMarkdownRenderer() {
    const renderer = new marked.Renderer();
    const linkRenderer = renderer.link;
    renderer.link = (href, title, text) => {

    const html = linkRenderer.call(renderer, href, title, text);
    return html.replace(/^<a /, '<a target="_blank" rel="noopener noreferrer" ');
    };

    marked.setOptions({ renderer });
}

export default configureMarkdownRenderer;
