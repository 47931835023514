import { ChatRequest } from "./models";
import { Log } from "./models";

/**
 * Parses the URL search parameters and returns an object containing the timezone, token, and search query.
 * If timezone is not provided in the URL, it defaults to "UTC".
 * @returns An object containing the timezone, token, and search query.
 */
function getUrlParams() {
    const urlParams = new URLSearchParams(window.location.search);
    let timezone = urlParams.get('timezone');
    let token = urlParams.get('at');
    let searchQuery = urlParams.get('searchQuery');
    if (!timezone) {
        timezone = "UTC";
    }
    return { timezone, token, searchQuery };
}

/**
 * Sends a chat request to the server and returns a Promise that resolves to a Response object.
 * @param options An object containing the chat request options.
 * @returns A Promise that resolves to a Response object.
 */
export async function chatApi(options: ChatRequest): Promise<Response> {
    const { timezone, token } = getUrlParams();
    const url = "api/intent";
    return await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
            chatHistory: options.history,
            question: options.question,
            followUpFlag: options.followUpFlag,
            timezone: timezone,
            sessionID: options.sessionID,
            conversationID: options.conver,
            logType: options.logType,
            messageID: options.messageID,
            exception: "",
            message: ""
        })
    });
}

/**
 * Logs a frontend exception to the server.
 * @param error - The error object to log.
 * @param sessionID - The ID of the current session.
 * @param conversationID - The ID of the current conversation.
 * @param messageID - The ID of the current message.
 * @returns A Promise that resolves when the exception has been logged.
 */
export async function exceptionLog(error: Error, sessionID: String, conversationID: String, messageID: String, functionName: String) {
    try {
        const { token } = getUrlParams();
        const response = await fetch("/api/logException", {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                chatHistory: [],
                question: "",
                followUpFlag: "",
                timezone: "",
                sessionID: sessionID,
                conversationID: conversationID,
                logType: "FrontendException",
                messageID: messageID,
                exception: error.stack,
                message: error.message + " in " + functionName
            })
        });
    } catch (error) {
        return;
    }
}

/**
 * Validates the given token by sending a GET request to the server.
 * @returns A Promise that resolves to the parsed response data.
 * @throws An error if the response status is not ok.
 */
export async function validateToken() {
    try {
        const { token } = getUrlParams();
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        };
        // Fetch the data using the .json() method to parse the response as JSON
        const response = await fetch(`/api/validateToken?token=${token}`, {
            method: 'GET',
            headers: headers
        });
        if (!response.ok) {
            return;
        }
        const data = await response.json();
        // Return the parsed data
        return data;
    } catch (error) {
        return;
    }
}

/**
 * Logs a custom conversation event.
 * @param options - The options for the conversation log.
 * @param options.sessionID - The session ID for the conversation.
 * @param options.conversationID - The conversation ID for the conversation.
 * @param options.messageID - The message ID for the conversation.
 * @param options.logType - The type of log to be recorded.
 * @param options.action - The action to be logged.
 * @param options.feedback - The feedback to be logged.
 * @returns A Promise that resolves to the response from the server.
 */
export async function conversationLog(options: Log) {
    try {
        const { timezone, token } = getUrlParams();
        const formData = new FormData();
        formData.append("sessionID", options.sessionID);
        formData.append("conversationID", options.conversationID);
        formData.append("messageID", options.messageID);
        formData.append("logtype", options.logType);
        formData.append("action", options.action);
        formData.append("feedback", options.feedback);
        formData.append("timezone", timezone);
        const response = fetch("/api/logCustom", {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: formData,
        })
    } catch (error) {
        return;
    }
}

/**
 * Fetches table data from the server.
 * @returns A Promise that resolves to the fetched data.
 * @throws An error if the API request fails.
 */
export async function fetchPromptGuide() {
    try {
        const { token } = getUrlParams();
        const response = await fetch('/api/fetchTableStorage', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        return;
    }
}


/**
 * kill switch functionality to stop all the actions in the bot
 */
export async function fetchKillSwitch() {
    try {
        const { token } = getUrlParams();
        const response = await fetch('/api/fetchKillSwitch', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        return;
    }
}


/**
 * Fetches welcome message from CDN
 */
export async function fetchWelcomeMessage() {
    try {
        const { token } = getUrlParams();
        const response = await fetch('/api/fetchWelcomeMessage', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            const welcomMsgConfig = await response.json();
            return welcomMsgConfig;
        } 
    } catch (error) {
        return;
    }
}


/**
 * Returns the file path for a given citation.
 * @param citation - The citation to get the file path for.
 * @returns The file path for the given citation.
 */
export function getCitationFilePath(citation: string): string {
    return `/content/${citation}`;
}

/**
 * Fetches the Word document from the server.
 * @returns A Promise that resolves to the fetched Word document.
 */
// Update the fetchWordDocument function
export async function fetchWordDocument(partnerMetadata: any, sessionMetadata: any): Promise<Blob> {
    try {
        const { token } = getUrlParams();

        const response = await fetch('/api/generateAndDownloadWordDoc', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ partnerMetadata, sessionMetadata }),
        });

        if (response.ok) {
            const arrayBuffer = await response.arrayBuffer();
            return new Blob([arrayBuffer], { type: 'application/msword' });
        } else {
            throw new Error('Failed to fetch Word document');
        }
    } catch (error) {
        throw new Error('Failed to fetch Word document');
    }
}

/**
 * The search query parameter extracted from the URL.
 */
export const searchQuery = getUrlParams().searchQuery;