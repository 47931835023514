import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initializeIcons } from "@fluentui/react";
import reportWebVitals from './reportWebVitals';

initializeIcons();

// Add event tracking logs
declare var oneDS: any;
const analytics = new oneDS.ApplicationInsights();
var config = {
    instrumentationKey: "b9106528506a4e7a836fcc95d2716ea1-aea9cce3-21bc-4db2-9c7e-506a6312e3a6-6803",
    channelConfiguration: { // Post channel configuration
        eventsLimitInMem: 50
    },
    propertyConfiguration: { // Properties Plugin configuration 
        env: "" // Environment can be set to PPE or PROD as needed. 
    },
    webAnalyticsConfiguration: { // Web Analytics Plugin configuration
        //urlCollectQuery:true, 
        autoCapture: {
            scroll: true,
            pageView: true,
            onLoad: true,
            onUnload: true,
            click: true,
            resize: true,
            jsError: true
        }
    }
};
//Initialize SDK
analytics.initialize(config, []);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

    <App />
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
