import { AskResponse } from "../../api";
import { defaultResponseAnswer, defaultResponseAnswerDigitalAuthenticatedUser, defaultResponseAnswerInpersonAuthenticatedUser } from "../../api/constants";
import moment from 'moment-timezone';

interface Props {
    attendeeType: string;
}


export function checkEventDate(): boolean {
    // Define the timezone ID for Pacific Standard Time
    const pacificTimeZone = 'America/Los_Angeles';

    // Get the current date and time in Pacific Time
    const currentDateTimePT = moment.tz(pacificTimeZone).format('YYYY-MM-DD HH:mm:ss');

    // Define the start and end dates for the event in Pacific Time
    const startDate = moment.tz("2024-05-21 14:00:00", pacificTimeZone);
    const endDate = moment.tz("2024-05-23 14:00:00", pacificTimeZone);

    // Check if the current date and time is within the specified range
    if (currentDateTimePT >= startDate.format('YYYY-MM-DD HH:mm:ss') && currentDateTimePT <= endDate.format('YYYY-MM-DD HH:mm:ss')) {
        return true;
    } else {
        return false;
    }

}


/**
 * Returns a default AskResponse object.
 * @returns {AskResponse} The default AskResponse object.
 */
export function DefaultAnswer({ attendeeType }: Props) {

    const validateEventDate = checkEventDate();

    let defaultRespAnswer = "";

    if ((attendeeType.toLowerCase().includes("digital")) && validateEventDate) {
        defaultRespAnswer = defaultResponseAnswerDigitalAuthenticatedUser.answer;
    } else if ((attendeeType.trim().toLowerCase().includes("in-person")) && validateEventDate) {
        defaultRespAnswer = defaultResponseAnswerInpersonAuthenticatedUser.answer;
    } else {
        defaultRespAnswer = defaultResponseAnswer.answer;
    }

    const defaultResponse: AskResponse = {
        answer: defaultRespAnswer,
        header: "",
        footer: "",
        thoughts: "",
        attendeeType: "",
        context: "",
        intent: "",
        metadata: [],
        sessionMetadata: [],
        partnerMetadata: [],
        userTimezone: ""
    };
    return defaultResponse;
}