import myImage from "./lock.jpg";
import './Error.css'

/**
 * Renders an error message for unauthorized users.
 * @returns JSX element containing the error message.
 */

function Error() {
    return (
        <div className="centered-container">
            <div>
                <img src={myImage} alt="My Error Page" />
                <h1>Unauthorized User</h1>
                <div> You have attempted to access a page for which you are not authorized.</div>
            </div>
        </div>
    );
}

export default Error;
