import React, { useState } from 'react';
import { Dialog, DialogType, DialogFooter, TextField, DefaultButton, PrimaryButton } from '@fluentui/react';

type SubmitCallback = (content: string) => void;

/**
 * A component that displays a popup dialog for submitting feedback.
 * @param isOpen - A boolean indicating whether the popup is open or not.
 * @param onDismiss - A function to dismiss the popup.
 * @param onSubmit - A function to submit the feedback content.
 */
function PopUp({ isOpen, onDismiss, onSubmit }: { isOpen: boolean; onDismiss: () => void; onSubmit: SubmitCallback }) {
    const [dialogContent, setDialogContent] = useState('');
    const [isContentSubmitted, setIsContentSubmitted] = useState(false);

    const characterLimit = 200;

    const handleInputChange = (newValue: any) => {
        if (newValue.length <= characterLimit) {
            setDialogContent(newValue);
        }
    };

    const handleSubmit = () => {
        onSubmit(dialogContent);
        setIsContentSubmitted(true); 
        setDialogContent(''); 
        onDismiss();
    };

    const handleDismiss = () => {
        setDialogContent(''); 
        if (!isContentSubmitted) {
            onSubmit('');
        }
        setIsContentSubmitted(false);
        onDismiss();
    };

    return (
        <Dialog
            hidden={!isOpen}
            onDismiss={handleDismiss}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Submit feedback',
                subText: 'Please tell us more about how we can improve this response:',
            }}
            modalProps={{
                isBlocking: false,
            }}
        >
                <TextField
                    multiline
                    rows={5}
                    value={dialogContent}
                    onChange={(e, newValue) => handleInputChange(newValue || '')}
                />
            <DialogFooter>
                <PrimaryButton onClick={handleSubmit} text="Submit" />
                <DefaultButton onClick={handleDismiss} text="Close" />
            </DialogFooter>
        </Dialog>
    );
}

export default PopUp;
