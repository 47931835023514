import styles from "./Layout.module.css";
import Chat from "../chat/Chat";
import { useState, useEffect } from "react";
import { GenerateUUID } from "../chat/Key";
import { conversationLog } from "../../api/api";
import { Log } from "../../api";

interface Props {
    attendeeType: string;
    welcomeMessages: any;
    scroller: any;
}

/**
 * Layout component that renders the chat UI.
 * @param followUpDefaultQuestions - Array of follow-up questions to be displayed to the user.
 */
const Layout = ({ attendeeType, welcomeMessages, scroller }:Props) => {
    const [sessionID, setsessionID] = useState('');
    const [conversationID, setConversationID] = useState('');
    const [messageID, setmessageID] = useState('');
    

    useEffect(() => {
        let newSessionID = "";
        let newConversationID = "";
        let sessionID = localStorage.getItem('newSessionID');
        let conversationID = localStorage.getItem('newConversationID');
        if (sessionID == null || conversationID == null) {
            newSessionID = GenerateUUID();
            newConversationID = GenerateUUID();
            setsessionID(newSessionID);
            setConversationID(newConversationID);
            localStorage.setItem('newSessionID', newSessionID);
            localStorage.setItem('newConversationID', newConversationID);
        }
        else {
            newSessionID = sessionID;
            newConversationID = conversationID;
            setsessionID(newSessionID);
            setConversationID(newConversationID);
        }
        const newMessageID = GenerateUUID();
        setmessageID(newMessageID);
        const log: Log = {
            sessionID: newSessionID,
            conversationID: newConversationID,
            messageID: newMessageID,
            logType: "Page Load",
            action: "Neutral",
            feedback: ""
        }
        if (sessionID == null)
        { 
        conversationLog(log);
        }

    }, []);

    return (
        <div className={styles.layout} >
            <Chat sessionID={sessionID}
                conversationID={conversationID}
                loadMessageID={messageID}
                attendeeType={attendeeType}
                attendeeTypeWelcomeMessage={welcomeMessages}
                scroller={scroller}
            />
        </div>
    );
};

export default Layout;
