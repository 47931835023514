/**
 * Enum representing the status of an answer.
 * @enum {string}
 */
export enum AnswerStatus {
    NEUTRAL = "NEUTRAL",
    LIKED = "LIKED",
    DISLIKED = "DISLIKED",
}

/**
 * Welcome message object containing a greeting message for the AI-powered assistant.
 */
export const WelcomeMessage = {
    message: "Hello! I\\'m your AI-powered assistant for Microsoft Build&mdash;here to help you make the most of your experience.",
};

/**
 * Default error message to display when the API call fails.
 * @type {Object}
 * @property {string} error - The error message to display.
 */
export const DefaultErrorMessage = {
    error: "Unfortunately I am unable to assist you. Please reach out to [build@microsoft.regsvc.com](mailto:build@microsoft.regsvc.com) for support.",
};

/**
 * Disclaimer for AI-generated content.
 */
export const ResponseDisclamier = {
    disclaimer: "AI-generated content may be incorrect",
};

/**
 * Default response answer object containing a message to be sent when the bot is unable to assist the user.
 */
export const defaultResponseAnswer = {
    answer: "Unfortunately I am unable to assist you. Please reach out to [build@microsoft.regsvc.com](mailto:build@microsoft.regsvc.com) for support.",
};

export const defaultResponseAnswerDigitalAuthenticatedUser = {
    answer: "Unfortunately I am unable to assist you. Please click [here](https://build.microsoft.com/chat-help) to connect with a live agent, or reach out to [build@microsoft.regsvc.com](mailto:build@microsoft.regsvc.com) for support.",
};

export const defaultResponseAnswerInpersonAuthenticatedUser = {
    answer: "Unfortunately I am unable to assist you. Please visit an Info Desk, click [here](https://build.microsoft.com/chat-help) to connect with a live agent, or reach out to [build@microsoft.regsvc.com](mailto:build@microsoft.regsvc.com) for support.",
};