import { Stack } from "@fluentui/react";
import { marked } from 'marked';
import { DefaultErrorMessage } from "../../api/constants";
import styles from "./Answer.module.css";

/**
 * Props for the AnswerError component.
 */

interface Props {
    error: string;
}

/**
 * Renders an error message for the answer component.
 * @param {Object} props - The component props.
 * @param {string} props.error - The error message to display.
 * @returns {JSX.Element} - The rendered component.
 */

export const AnswerError = ({ error }: Props) => {

    let defaultError = DefaultErrorMessage.error;
    return (
        <Stack className={styles.answerContainer} verticalAlign="space-between">
            <Stack.Item grow>
                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: marked(defaultError) }}></div>
            </Stack.Item>
        </Stack>
    );
};
