import { ChatAdd20Regular } from "@fluentui/react-icons";
import styles from "./ClearChatButton.module.css";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}

/**
 * A button component that clears the chat when clicked.
 * @param className - Additional CSS class name(s) to apply to the container element.
 * @param disabled - Whether the button is disabled or not.
 * @param onClick - The function to call when the button is clicked.
 */

export const ClearChatButton = ({ className, disabled, onClick }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""} ${disabled && styles.disabled}`}>
            <button data-m='{"aN": "chattextbox", "cN": "Clear Chat"}' className={styles.button} onClick={onClick} disabled={disabled}  title="New chat">
                <ChatAdd20Regular />
            </button>
        </div>
    );
};