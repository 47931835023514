/**
 * Generates a unique UUID string.
 * @returns {string} The generated UUID string.
 */
function GenerateUUID(): string {
    const timestamp = Date.now().toString(16); 
    const randomPart = window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16); 
    const timestamp1 = Date.now(); 
   
    const customUUID = `${timestamp}-${randomPart}`;

    return customUUID;
}

export { GenerateUUID }
/*  export default Layout;*/